.contact-sidebar-v1 {
	background-color: var(--color-bg-light);

	.h5 {
		font-family: var(--font-family-main);
		font-weight: 600;
	}

	.logo {
		//max-width: 230px;
	}

	.openstreetwarning {
		display: block;
	}

	.contact-map {
		width: 100%;
		height: 250px;
	}

	p {
		font-size: var(--font-size-md);
	}

	.contact-bl {
		display: flex;
		margin-bottom: 15px;
		position: relative;
		padding-left: 40px;
		min-height: 30px;
		align-items: center;
		font-size: var(--font-size-md);

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: var(--color-black);

			&:hover {
				color: var(--color-primary);
			}
		}


		i {
			color: var(--color-white);
			background-color: var(--color-secondary);
			padding: 6px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 2px;
			box-shadow: 0 0 10px rgba(0, 0, 0, .18);
			font-size: 16px;

			&.icon {
				img {
					width: 100%;
				}

			}
		}


	}

	.openings {
		div {
			margin-top: 10px;
			font-size: var(--font-size-md);

		}
	}

	.last-entry {
		padding-bottom: 5px;
	}

	.openstreetwarning {
		aspect-ratio: inherit;
	}
}