.static-view {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.faq {
			background-image: url(RESOURCE/img/faq_hero.webp);
		}

		&.hooksiel {
			background-image: url(RESOURCE/img/hooksiel_hero.webp);
		}

		&.ueber-uns {
			background-image: url(RESOURCE/img/ueber_uns_hero.webp);
		}

		&.contact-banner {
			background-image: url(RESOURCE/img/kontakt_hero.webp);
		}

		&.eigentuemerservice-banner {
			background-image: url(RESOURCE/img/vermieter_hero.webp);
		}
	}

	&.hooksiel-v1 {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.hooksiel-gallery {
			display: flex;

			@media (max-width:767px) {
				margin: 0;
				flex-direction: column;
			}

			li {
				padding: 0 5px;

				@media (max-width:767px) {
					margin-bottom: 25px;
					padding: 0;
				}
			}
		}
	}

	&.ueber-uns-v1 {
		img {
			width: 100%;
		}
	}

	a {
		font-weight: 700;
	}
}