.feedbacks-v1 {
	.total-review-bl {

		.cl-rat {
			i {
				background-image: url(RESOURCE/img/star_icon_yellow.svg);
				background-size: contain;
				height: 20px;
				width: 20px;
				background-repeat: no-repeat;
				filter: inherit;
			}
		}
	}

	.owl-carousel {
		/*background: var(--color-white);*/
		padding: 0 30px;
		border-radius: 5px;
		/*margin-bottom: 50px;*/

		@media (max-width: 767px) {
			padding: 0 20px;
		}

		.cl-rat {
			margin: 20px 0 10px;
			text-align: left;

			i {
				color: var(--color-dark-blue);
			}
		}

		.cl-dt {
			a {
				//font-size: 13px;
			}
		}

		.owl-dots {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 50px 0 0 0;
			display: none;

			@media (max-width: 767px) {
				margin: 30px 0 0 0;
			}

			.owl-dot {
				width: 8px;
				height: 8px;
				background: #b5b5b5;
				margin: 0 5px;
				border-radius: 100px;

				&.active {
					background: var(--color-primary);
				}
			}
		}

		.owl-nav {
			button {
				position: absolute;
				top: 140px;

				font-size: 55px;
				line-height: 0;
				opacity: 0.3;
				margin-top: -10px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);

				&.owl-next {
					right: 0;
				}

				&.owl-prev {
					left: 0;
				}
			}
		}

		h3,
		.h3 {
			margin: 0 0 20px 0;
		}

		.cl-cont-bl {
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			margin: 0;
			padding: 35px 25px 25px 25px;
			background: var(--color-white);
			border-radius: 5px;
			height: 100%;

			h2 {
				font-size: 1rem;
				text-align: left;
			}

			&:before {
				content: "''";
				position: absolute;
				top: 70px;
				left: 40px;
				font-size: 50px;
				color: #000;
				line-height: 20px;

				@media (max-width: 767px) {
					top: 20px;
				}
			}

			.feedback-header {
				display: flex;
				text-align: left;

				.avtar {
					height: 60px;
					width: 60px;
					margin-right: 15px;
					border-radius: 50%;
					overflow: hidden;
				}

				h2 {
					font-size: 18px;
					margin: 0;
				}

				span {
					font-size: 14px;
				}
			}
		}
	}

}

.testimonial-slide {
	.owl-stage {
		display: flex;

		.owl-item {
			.item {
				height: 100%;
				padding: 48px 18px;

				@media (max-width: 767px) {
					padding: 0 22px 20px 22px;
				}

				.cl-cont-bl {}
			}
		}
	}
}