.urlaub-mit-hund-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-hund.webp);
	}

	.img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}
}

.urlaub-mit-hund {
	.img {
		width: 100%;
	}
}